FormIO.$inject = ['logService', '$scope', 'allevaApi', '$window', 'noty', '$interval', '$timeout'];
export default function FormIO(logService, $scope, allevaApi, $window, noty, $interval, $timeout) {
    /***************************
     * Params
     **************************/
    // UI
    $scope.formSubmitting = false;
    $scope.countDownTime = 0;

    // Data
    $scope.Formio = $window.Formio;
    $scope.formModel = null; // Model from parent controller
    $scope.form = null; // form created by FormIO
    $scope.applicant = null;
    $scope.autosaveTimer = null;

    // Constants
    $scope._autoSaveTime = 6;
   
    /***************************
     * Init
     **************************/
    $scope.initForm = (formModel, applicant, currentUser) => {
            // combine with createForm and move to $oninit
        logService.console.log("FormIO");
        $scope.formModel = formModel;
        $scope.applicant = applicant;
        $scope.currentUser = currentUser;
    };

    $scope.initFormView = (formModel) => {
        logService.console.log("FormIO");
        $scope.formModel = formModel;
    };

    $scope.createForm = (readOnly, formId = null, advancedFormId = null) => {
        advancedFormId = advancedFormId || $scope.formModel.advancedFormId;
        $timeout(() => { // Allow time for div to show on screen
            if(advancedFormId) {
                createAdvancedForm(readOnly, advancedFormId);
                $scope.form = {
                    click: function(){
                    },
                    submit: function(){
                        $scope.$broadcast("AdvancedForm.requestSave", $scope.formModel.saveForLater);
                    }
                };
            } else {
                createFormio(readOnly, formId);
            }
        }, 200);
    };

    function createAdvancedForm(readOnly, advancedFormId){
        allevaApi.AdvancedForms.getAdvancedForm(advancedFormId)
            .then(result => {
                const formMode = readOnly ? 'readonly' : 'family-portal';
                const leadId = $scope.applicant ? $scope.applicant.leadId || $scope.formModel.leadId : $scope.formModel.leadId;
                $scope.advancedFormHtml = `<advanced-form form-mode="${formMode}" id="advancedForm_${advancedFormId}" lead-id="${leadId}" form-object="form" advanced-form-id="${advancedFormId}">${result.body}</advanced-form>`;
            });
    };

    function createFormio(readOnly, formId = null){
        var elementId = formId == null ? '#formio-form' : '#formio-form' + formId;
        var formElement = angular.element( document.querySelector( elementId ));
        var divElement = formElement[0];
        readOnly = readOnly || false;
        $scope.Formio.createForm(divElement, JSON.parse($scope.formModel.layout), {
            readOnly,
            viewAsHtml: false,
            hooks: {}
        })
        .then((form) => {
            $scope.form = form;

            // Prevent posting to formio server
            form.nosubmit = true;

            if ($scope.formModel.savedData) {
                form.submission = { data: JSON.parse($scope.formModel.savedData)};
            }

            // Replace submit button with custom button! (optional)
            removeElementsByClass('formio-component-submit');

            form.on('error', () => {
                if(!$scope.formModel.saveFromAutosave){
                    var $scrollTo = $('.formio-errors');

                    // var offset = $scrollTo.offset();
                    // $container.animate({scrollTop: $scrollTo.offset().top - $container.offset().top + $container.scrollTop(), scrollLeft: 0},300);
                    $('html, body').animate({
                        scrollTop: $scrollTo.offset().top - 150
                    }, 'fast'); // 'fast' is for fast animation
                }

                let alert = "<h5>Incomplete</h5><p>Sorry, you must fill out the required fields before saving.</p>";
                new noty({
                    text: alert,
                    timeout: 20000
                }).show();
                $scope.formError();
            });

            form.on('submit', (submission) => {
                $interval.cancel($scope.autosaveTimer);
                $scope.formSubmitClicked(submission);
            });

            form.on('change', function (event) {
                if(event.changed){
                    if($scope.initialLoad){
                        $scope.startAutosaveTimer();
                    }
                    else{
                        $scope.initialLoad = true;
                    }
                }
            });
        
        });
    }

    $scope.$on('AdvancedForm.saveComplete', function(e,saveForLater, fromAutosave) { 
        if (fromAutosave){
            $scope.formModel.saveFromAutosave = fromAutosave;
            $scope.formModel.saveForLater = true;
        }
        const form = $scope.formModel;

        if(saveForLater){
            form.inProgress = 1;
        }else{
            form.inProgress = 0;
            form.isCompleted = 1;
        }

        $scope.formSubmitClicked(form);
    });

    /***************************
     * Form Callbacks
     **************************/
    $scope.formSubmitClicked = (submission) => {

        $scope.submitForm(submission)
        .then(form => {
            if(!$scope.formModel.saveFromAutosave){
                $interval.cancel($scope.autosaveTimer);
            }

            // Parent callback
            if($scope.formModel.formSubmitted){
                $scope.formModel.formSubmitted(form);
            }

            logService.console.info('Form', form);
            $scope.formModel.saveForLater = false; // reset this

        })
        .catch((error) => {
            logService.console.error(error);
        });

        // Remove success alert on form
        removeElementsByClass('alert alert-success');

    };

    $scope.saveForLaterClicked = () => {
        $interval.cancel($scope.autosaveTimer);
        $scope.formModel.saveFromAutosave = false;
        $scope.formModel.saveForLater = true;
        
        if($scope.form.AdvancedFormId)
            $scope.$broadcast("AdvancedForm.requestSave", $scope.formModel.saveForLater);
        else
            $scope.form.submit();
    };

    $scope.formError = () => {
        // custom error handling
    };

    $scope.startAutosaveTimer = () => {
        $interval.cancel($scope.autosaveTimer); // reset timer
        $scope.countDownTime = $scope._autoSaveTime;    
        $scope.autosaveTimer = $interval( () => {
            logService.console.log("Autosaving in... " + $scope.countDownTime--);

            if($scope.countDownTime === 0){
                $scope.formModel.saveFromAutosave = true;
                $scope.formModel.saveForLater = true;
                $interval.cancel($scope.autosaveTimer);
                $scope.form.submit();
            }
        },1000,$scope._autoSaveTime);
    };
  

    /***************************
     * CRUD
     **************************/
    // POST
    $scope.submitForm = (submission) => {

        $scope.formSubmitting = true;

        // v3 uses FormDataId for InstanceId -- without renaming this field, this can be confusing
        var postdata = {
            rehabUserId: $scope.currentUser.rehabUserId,
            formInfoId: $scope.formModel.formInfoId,
            advancedFormId: $scope.formModel.advancedFormId,
            formDataId: $scope.formModel.formDataId,
            familyFormId: $scope.formModel.familyFormId,
            familyFormData: {
                SavedData: JSON.stringify(submission.data),
                FormLayoutId: $scope.formModel.parentFormLayoutId > 0 ? $scope.formModel.parentFormLayoutId : $scope.formModel.formLayoutId,
                FormDataId: $scope.formModel.formDataId
            },
            leadId: $scope.applicant.leadId,
            createdBy: $scope.currentUser.rehabUserId,
            createdDate: $scope.formModel.createdDate ? $scope.formModel.createdDate : new Date(),
            modifiedDate: new Date(),
            title: $scope.formModel.title,
            saveForLater: $scope.formModel.saveForLater
        };

        return allevaApi.Forms.submitForm(postdata)
            .then(result => {
                return Promise.resolve(result);
            })
            .catch(error => {
                return Promise.reject(error);
            })
            .finally(() => {
                $scope.formSubmitting = false;
            });
    };
  
    /***************************
     * Helpers
     **************************/
    function removeElementsByClass(className){
        var elements = document.getElementsByClassName(className);
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    }
  
}
