LoginController.$inject = ['logService', '$scope', '$localStorage', 'allevaApi', '$timeout', 'noty', '$state', '$location', 'UserManager', 'User', '$rootScope'];
export default function LoginController(logService, $scope, $localStorage, allevaApi, $timeout, noty, $state, $location, UserManager, User, $rootScope) {
  /***************************
   * Params
   **************************/
  // Page
  $scope.setPageTitle("Alleva Family - Login");
  $scope.LoginController = $scope
  // UI
  $scope.isLoading = false;
  $scope.loggingIn = false;
  $scope.signingUp = false;
  $scope.isRedirecting = false;

  $scope.isLogin = false;
  $scope.isLoginCredsInvalid = false;
  $scope.isSignup = false;
  $scope.isUserRole = false;
  $scope.isSignupConfirmation = false;
  $scope.isSelectFacility = false;
  $scope.isForgotPassword = false;
  $scope.isForgotPasswordConfirm = false;
  $scope.isForgotUsername = false;
  $scope.isForgotUsernameConfirm = false;
  $scope.isSSOConfirm = false;
  $scope.user = null;
  $scope.facilityId = 0;

  // Buttons
  $scope.forMyselfSelected = false;
  $scope.familyMemberSelected = false;

  // Data
  $scope.loginInfo = {
    subdomain: $scope._Subdomain
  };
  $scope.signupInfo = {};
  $scope.signupUser = null;
  $scope.forgotPassword = {};
  $scope.forgotUsername = {};

  // Page
  $scope.loginForms = {
    loginForm: null,
    signupForm: null
  };
  // oidc-client configuration
  //$rootScope.isSSOEnabled         =true;

  $scope.odicClientSettings = {};
  var https = 'https://';
  var silentRefresh = '#!/silent-refresh.html'

  $scope.odicClientSettings.authority = ALLEVA_SSO_HOST;
  $scope.odicClientSettings.client_id = CLIENT_ID;
  $scope.odicClientSettings.redirect_uri = https + $location.absUrl().split('/')[2] + '/' + CALL_BACK_PAGE;
  $scope.odicClientSettings.post_logout_redirect_uri = https + $location.absUrl().split('/')[2];
  $scope.odicClientSettings.response_type = IDENTITY_CODE;
  $scope.odicClientSettings.scope = IDENTITY_SCOPE;
  $scope.odicClientSettings.filterProtocolClaims = true;
  $scope.odicClientSettings.loadUserInfo = true;
  $scope.odicClientSettings.getclaimsfromuserinfoendpoint = true;
  $scope.odicClientSettings.staleStateAge = 300;
  $scope.odicClientSettings.silent_redirect_uri = https + $location.absUrl().split('/')[2] + '/' + silentRefresh;
  $scope.odicClientSettings.acr_values = 'tenant:' + ($location.absUrl().split('/')[2]).split('.')[0];

  $rootScope.manager = new UserManager($scope.odicClientSettings);
  $localStorage.odicClientSettings = $scope.odicClientSettings;

  $scope.user = User | null;

  /***************************
   * Init
   **************************/
  $scope.initLogin = () => {
    $scope.isLoading = false;
    if ($location.search().open == 'forget-username') {
      $scope.goToForgotUsername();
    }
    else if ($location.search().open == 'forget-password') {
      $scope.goToForgotPassword();
    }
    else if ($location.search().open == 'create-user') {
      $scope.goToUserRole();
    }
    else {
      if ($location.search().facility) {
        $scope.signupNextClick();
      }
      else {
        var authUser = $localStorage.authUser;
        if (authUser) {
          $state.go('home', {});
        }
        else {
          $scope.isRedirecting = true;
          try {
            var _username = '';
            if ($localStorage.nextloginusername != '' && $localStorage.nextloginusername != undefined) {
              _username = $localStorage.nextloginusername;
              $localStorage.nextloginusername = '';
            }
            $rootScope.manager.signinRedirect({ extraQueryParams: { username: _username } });
          }
          catch (e) {
            $scope.isLoading = true;
          }
        }
      }
    }
  };

  // auth call back
  $scope.authCallBackHandler = async () => {
    var user = await $rootScope.manager.signinRedirectCallback();
    let authUser = {
      'email': user.profile.email,
      'firstName': user.profile.firstName,
      'id': user.profile.applicationUserId,
      'lastName': user.profile.lastName,
      'token': user.access_token,
      'id_token': user.id_token,
      'username': user.profile[CLAIM_NAME_CODE]
    };
    $scope.isLogin = false;
    $localStorage.authUser = authUser;
    $scope.isLoginCredsInvalid = false;
    $localStorage.access_token = authUser.token;
    $localStorage.id_token = authUser.id_token;
    await $rootScope.manager.clearStaleState();
    // Go to Home Page
    $scope.login(() => {
      $scope.isLoading = false;
    });
  };
  /***************************
   * Actions
   **************************/
  $scope.loginClick = (isRelog = false) => {
    // Validate Form
    if (!isRelog && $scope.login.loginForm.$invalid) {
      return;
    }

    $scope.isLoading = true;

    $scope.loginUser()
      .then(response => {
        $scope.isLoginCredsInvalid = false;
        $localStorage.authUser.locked = false;
        logService.console.info("authUser", $localStorage.authUser);

        if (isRelog) {
          let alert = "<p>Successfully logged back in.</p>";
          new noty({
            text: alert,
            type: 'success'
          }).show();
        }

        // Go to Home Page
        $scope.login(() => {
          $scope.isLoading = false;
        });
      })
      .catch(error => {
        $scope.isLoading = false;
        $scope.isLoginCredsInvalid = true;
        logService.console.error(error);
      });
  };

  // From lock screen
  $scope.relogUser = (form) => {
    // Validate Form
    if (form.$invalid) {
      return;
    }
    $scope.loginInfo.username = $localStorage.authUser.username;
    $scope.loginInfo.password = form.password.$viewValue;
    $scope.loginClick(true);
  };

  $scope.signUpClick = (form, ssoConsent) => {
    noty.closeAll();

    // Validate Form
    if (form.$invalid) {
      let alert = "<h5>Please fill out the required fields.</h5>";
      new noty({
        text: alert,
        type: 'warning',
      }).show();
      return;
    }

    //Validate Username
    var valid = true;
    if ($scope.signupInfo.username.length <= 3) {
      let alert = "<p>Username is <b class='noty-bold'>too short</b></p>";
      new noty({
        text: alert,
        timeout: false,
        type: 'warning',
      }).show();
      valid = false;
    }
    if ($scope.signupInfo.username.length >= 15) {
      let alert = "<p>Username is <b class='noty-bold'>too long</b></p>";
      new noty({
        text: alert,
        timeout: false,
        type: 'warning',
      }).show();
      valid = false;
    }
    if ($scope.signupInfo.username.indexOf(' ') >= 0) {
      let alert = "<p>Username cannot contain <b class='noty-bold'>white space</b></p>";
      new noty({
        text: alert,
        timeout: false,
        type: 'warning',
      }).show();
      valid = false;
    }

    //Validate PWs
    if ($scope.signupInfo.password.length < 8) {
      let alert = "<p>Passwords must be at least <b class='noty-bold'>8 characters</b></p>";
      new noty({
        text: alert,
        timeout: false,
        type: 'warning',
      }).show();
      valid = false;
    }

    if (!(/[a-z]/.test($scope.signupInfo.password))) {
      let alert = "<p>Passwords must have at least <b class='noty-bold'>one lowercase (a-z)</b></p>";
      new noty({
        text: alert,
        timeout: false,
        type: 'warning',
      }).show();
      valid = false;
    }

    if (!(/[A-Z]/.test($scope.signupInfo.password))) {
      let alert = "<p>Passwords must have at least <b class='noty-bold'>one uppercase (A-Z)</b></p>";
      new noty({
        text: alert,
        timeout: false,
        type: 'warning',
      }).show();
      valid = false;
    }

    if (!(/[0-9]/.test($scope.signupInfo.password))) {
      let alert = "<p>Passwords must have at least <b class='noty-bold'>one digit (0-9)</b></p>";
      new noty({
        text: alert,
        timeout: false,
        type: 'warning',
      }).show();
      valid = false;
    }
    if (!(/[~`!#$%\^&*+=\-\[\]\\';@,/{}|\\":<>\?]/g.test($scope.signupInfo.password))) {
      let alert = "<p>Passwords must have at least <b class='noty-bold'>one non-alphanumeric character</b></p>";
      new noty({
        text: alert,
        timeout: false,
        type: 'warning',
      }).show();
      valid = false;
    }

    if (!valid) {
      return false;
    }

    if ($scope.signupInfo.password != $scope.signupInfo.cPassword) {
      let alert = "<h5>Passwords must match.</h5>";
      new noty({
        text: alert,
        type: 'warning',
      }).show();
      $scope.invalidPW = true;
      return false;
    }

    $scope.ssoUserConsent = ssoConsent;
    $scope.isLoading = true;

    if (ssoConsent == false) {
      $scope.ssoEligibleCheck()
        .then((result) => {
          if (result == true) {
            $scope.isSignup = false;
            $scope.isSSOConfirm = true;
            $scope.isLoading = false;
            return false;
          }

          $scope.signup()
            .then((result) => {
              logService.console.log("Auth User Created");

              // Create the facility user
              $scope.createRehabUser(result.user)
                .then((result) => {
                  logService.console.log("Rehab User Created");
                  $scope.isLoading = false;
                  $localStorage.nextloginusername = result.username;
                  $scope.goToSignupConfirmation();
                })
                .catch((error) => {
                  logService.console.error(error);
                  $scope.isLoading = false;

                  if (error.errorMessage) {
                    new noty({
                      text: error.errorMessage
                    }).show();
                  }
                });
            })
            .catch((error) => {
              logService.console.error(error);
              $scope.isLoading = false;

              if (error.errorMessage) {
                new noty({
                  text: error.errorMessage
                }).show();
              }
            });
        })
        .catch((error) => {
          logService.console.error(error);
          $scope.isLoading = false;

          if (error.errorMessage) {
            new noty({
              text: error.errorMessage
            }).show();
          }
        });
    }
    else {
      $scope.isLoading = true;
      $scope.signup()
        .then((result) => {
          logService.console.log("Auth User Created");

          // Create the facility user
          $scope.createRehabUser(result.user)
            .then((result) => {
              logService.console.log("Rehab User Created");
              $scope.isLoading = false;
              $localStorage.nextloginusername = result.username;
              $scope.goToSignupConfirmation();
            })
            .catch((error) => {
              logService.console.error(error);
              $scope.isLoading = false;

              if (error.errorMessage) {
                new noty({
                  text: error.errorMessage
                }).show();
              }
            });
        })
        .catch((error) => {
          logService.console.error(error);
          $scope.isLoading = false;

          if (error.errorMessage) {
            new noty({
              text: error.errorMessage
            }).show();
          }
        });
    }
  };

  $scope.forgotUsernameSubmit = (form) => {
    noty.closeAll();
    // Validate Form
    if (form.$invalid) {
      let alert = "<h5>Please fill out the required fields.</h5>";
      new noty({
        text: alert,
        type: 'warning',
      }).show();
      return;
    }

    $scope.isLoading = true;

    $scope.forgotUsernamePost()
      .then(response => {
        logService.console.info("Forgot Username Response", response);
        $scope.goToForgotUsernameConfirm();
        $scope.isLoading = false;
      })
      .catch(error => {
        logService.console.error(error);
        $scope.isLoading = false;
      });
  };

  $scope.forgotPasswordSubmit = (form) => {
    noty.closeAll();

    // Validate Form
    if (form.$invalid) {
      let alert = "<h5>Please fill out the required fields.</h5>";
      new noty({
        text: alert,
        type: 'warning',
      }).show();
      return;
    }

    $scope.isLoading = true;

    $scope.forgotPasswordPost()
      .then(response => {
        logService.console.info("Forgot Password Response", response);
        $scope.goToForgotPasswordConfirm();
        $scope.isLoading = false;
      })
      .catch(error => {
        logService.console.error(error);
        $scope.isLoading = false;
      });
  };

  $scope.signupNextClick = () => {
    $scope.goToUserRole();
  };

  $scope.facilitySelected = (facility) => {
    $scope.rehabDetails.facilities.forEach(fac => {
      fac.isSelected = false;
    });

    facility.isSelected = true;
    $scope.facilityId = facility.rehabFacilityId;

    $timeout(() => {
      $scope.goToSignup();
    }, 750);
  };

  /***************************
   * CRUD
   **************************/
  //POST
  $scope.loginUser = () => {
    let params = {
      "userName": $scope.loginInfo.username,
      "password": $scope.loginInfo.password,
      "clientId": CLIENT_ID,
      "tenantId": $scope.loginInfo.subdomain
    };

    return allevaApi.Auth.authenticate(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };

  $scope.forgotUsernamePost = () => {
    let params = {
      "email": $scope.forgotUsername.email,
      "rehabSubdomain": $scope._Subdomain,
      "rehabName": $scope.rehabDetails.rehabName,
      "rehabEmail": $scope.rehabDetails.supportEmail,
      "clientId": 'alleva.family.portal'      
    };

    return allevaApi.Auth.forgotUsername(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };

  $scope.forgotPasswordPost = () => {
    let params = {
      "email": $scope.forgotPassword.email,
      "rehabSubdomain": $scope._Subdomain,
      "rehabName": $scope.rehabDetails.rehabName,
      "rehabEmail": $scope.rehabDetails.supportEmail,
      "clientId": 'alleva.family.portal'      
    };

    return allevaApi.Auth.forgotPassword(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };

  $scope.signup = () => {
    let params = {
      "username": $scope.signupInfo.username,
      "password": $scope.signupInfo.password,
      "email": $scope.signupInfo.create_email,
      "firstName": $scope.signupInfo.fName,
      "lastName": $scope.signupInfo.lName,
      "role": $scope.signupInfo.roleName,
      "domainName": $scope._Subdomain,
      "ssoUserConsent": $scope.ssoUserConsent,
      "clientId": 'alleva.family.portal'
    };

    return allevaApi.Auth.signup(params)
      .then((response) => {
        if (response.user) {
          return Promise.resolve(response);
        }
        return Promise.reject(response);
      });
  };

  $scope.ssoEligibleCheck = () => {
    let params = {
      "username": $scope.signupInfo.username,
      "password": $scope.signupInfo.password,
      "email": $scope.signupInfo.create_email,
      "firstName": $scope.signupInfo.fName,
      "lastName": $scope.signupInfo.lName,
      "role": $scope.signupInfo.roleName,
      "domainName": $scope._Subdomain,
      "clientId": 'alleva.family.portal'      
    };

    return allevaApi.Auth.ssoEligibleCheck(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };

  $scope.createRehabUser = (user) => {
    let params = {
      "ApplicationUserId": user.id,
      "Username": user.username,
      "Email": user.email,
      "FirstName": user.firstName,
      "LastName": user.lastName,
      "Role": $scope.signupInfo.role,
      "RehabId": $scope.rehabDetails.rehabId,
      "FacilityId": $scope.facilityId,
      "RehabName": $scope._Subdomain,
      "CreatedBy": -2, // Family app default
      "CreatedDate": new Date(),
      "IsActive": true,
      "fromInvite": $scope.fromInvite,
      "fromPortal": true
    };

    return allevaApi.FamilyAPI.createRehabUser(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
  $scope.getUserInfo = () => {
    return allevaApi.Auth.getUserInfo()
  };

  /***************************
   * Navigation
   **************************/
  $scope.resetViews = () => {
    $scope.isLogin = false;
    $scope.isSignup = false;
    $scope.isUserRole = false;
    $scope.isSignupConfirmation = false;
    $scope.isSelectFacility = false;
    $scope.isForgotPassword = false;
    $scope.isForgotPasswordConfirm = false;
    $scope.isForgotUsername = false;
    $scope.isForgotUsernameConfirm = false;
    $scope.isSSOConfirm = false;
  };

  $scope.goToLogin = () => {
    noty.closeAll();
    $scope.resetViews();
    if (angular.isDefined($scope.forgotUsername)) {
      $scope.forgotUsername = {};
    }
    if (angular.isDefined($scope.forgotPassword)) {
      $scope.forgotPassword = {};
    }

    $scope.isLogin = false;
    location.href = "/";
  };

  $scope.goToSignup = () => {
    if ($location.search().email) {
      $scope.fromInvite = true;
    } else {
      $scope.fromInvite = false;
    }
    $scope.signupInfo.fName = $location.search().fname;
    $scope.signupInfo.lName = $location.search().lname;
    $scope.signupInfo.create_email = $location.search().email;

    $scope.resetViews();
    $scope.isSignup = true;
  };

  $scope.goToSignupFilledForm = () => {
    if ($location.search().email) {
      $scope.fromInvite = true;
    } else {
      $scope.fromInvite = false;
    }

    $scope.resetViews();
    $scope.isSSOConfirm = false;
    $scope.isSignup = true;
  };

  $scope.goToSelectFacility = () => {
    $scope.facilityId = $location.search().facility;
    if ($scope.facilityId) { // Bypass facility selection
      $scope.goToSignup();
      return;
    }

    $scope.resetViews();
    $scope.isSelectFacility = true;
  };

  $scope.goToUserRole = () => {
    $scope.forMyselfSelected = false;
    $scope.familyMemberSelected = false;

    $scope.resetViews();
    $scope.isUserRole = true;
  };

  $scope.goToSignupConfirmation = () => {
    $scope.resetViews();
    $scope.isSignupConfirmation = true;
  };

  $scope.goToForgotPassword = () => {
    $scope.resetViews();
    $scope.isForgotPassword = true;
  };

  $scope.goToForgotUsername = () => {
    $scope.resetViews();
    $scope.isForgotUsername = true;
  };

  $scope.goToForgotPasswordConfirm = () => {
    $scope.resetViews();
    $scope.isForgotPasswordConfirm = true;
  };

  $scope.goToForgotUsernameConfirm = () => {
    $scope.resetViews();
    $scope.isForgotUsernameConfirm = true;
  };

  /***************************
   * Helpers
   **************************/
  $scope.toggleRole = (role) => {
    noty.closeAll();

    $scope.signupInfo.role = role.id;
    $scope.signupInfo.roleName = role.name;

    $timeout(() => {
      $scope.goToSelectFacility();
    }, 750);
  };

  $rootScope.manager.events.addAccessTokenExpiring(newUser => {
    $rootScope.manager.startSilentRenew();
  });

  $rootScope.manager.events.addUserLoaded(newUser => {
    $localStorage.access_token = newUser.access_token;
    if ($localStorage.authUser != null) {
      $localStorage.authUser.token = newUser.access_token;
      $localStorage.authUser.id_token = newUser.id_token;
    }
  });

  $rootScope.manager.events.addUserSignedOut(newUser => {
    $scope.logout();
  });
}